type NavItem = {
    title: string,
    _path: string,
    link?: string,
    to?: string,
    order?: number,
    target?: string,
    children: NavItem[]
};

export default async function useSiteNavigation() {
    const _pathTo = (data: NavItem[]) : NavItem[] => {
        return data.map(item => {
            item.to = item._path;

            if (item.link) {
                item.to = item.link;
                item.target = '_blank';
            }

            if (item.children) {
                item.children = _pathTo(item.children);
            }

            return item;
        });
    };

    const {data: navigation} = await useAsyncData('navigation', () => fetchContentNavigation(), {
        transform(data: NavItem[]) {
            return _pathTo(data).sort((a: NavItem, b: NavItem) => (a.order || 0) - (b.order || 0));
        }
    });

    const getPage = (path: String, items = navigation) => {
        if (items.value) {
            items = items.value;
        }

        return [...items].find((item) => {
            if (item._path === path) {
                return true
            }

            if (item.children) {
                return getPage(path, item.children);
            }
        })

        return true;
    }

    return {navigation, getPage};
};
