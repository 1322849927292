<template>
    <UINavigation :links="links" :ui="{base: 'text-sm lg:text-base'}"/>

    <div class="md:hidden col-start-4 col-end-5 flex justify-end">
        <UButton
            size="xl"
            color="primary"
            square
            variant="ghost"
            @click.prevent="isOpen = !isOpen"
            :padded="false"
            class="text-current"
        >
            <template #trailing>
                <UIIcon name="front/bars" class="w-8 h-8 ml-2.5"/>
            </template>
        </UButton>
    </div>

    <Teleport to="#teleports">
        <div>
            <Transition name="fade">
                <div v-show="isOpen" @click="isOpen = !isOpen" class="fixed inset-0 opacity-70 bg-black z-10"></div>
            </Transition>
            <Transition name="slide-fade">
                <div v-show="isOpen" class="fixed top-0 right-0 rounded-l-0.5xl w-9/12 bottom-0 bg-white z-20 p-5 pt-20">
                    <UButton
                        size="lg"
                        color="gray"
                        square
                        variant="ghost"
                        @click.prevent="isOpen = !isOpen"
                        class="absolute right-1 top-1"
                    >
                        <template #trailing>
                            <ClientOnly>
                                <UIIcon name="front/close" class="w-6 h-6"/>
                            </ClientOnly>
                        </template>
                    </UButton>

                    <UIMobileNavigation
                        :links="links"
                        :ui="{
                            base: 'h-11 justify-end font-semibold bg-inherit text-slate-800',
                            active: 'text-blue-700 bg-inherit before:bg-inherit'
                        }"
                    />
                </div>
            </Transition>
        </div>
    </Teleport>
</template>

<script setup>
defineProps(['links']);
const isOpen = ref(false);

const route = useRoute();
watch(() => route.path, () => isOpen.value = false);
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
