<template>
    <ULink
        :class="[ui.base, ui.padding, ui.width, ui.ring, ui.rounded, ui.font, ui.size]"
        :active-class="ui.active"
        :to="link.children ? null : link._path"
        @click="isOpen = !isOpen"
    >
        <span class="truncate relative">{{ link.title }}</span>

        <UIcon v-if="link.children" name="heroicons:chevron-down-16-solid" class="w-6 h-6 -mr-2" :class="{'rotate-180': isOpen}"></UIcon>
    </ULink>

    <Transition>
        <ul v-if="link.children" v-show="isOpen" class="grid pr-7 max-h-96 overflow-y-auto overflow-x-hidden mb-7">
            <li v-for="(link, index) of link.children" :key="`link-${index}`" class="text-right">
                <ULink
                    class="h-11 inline-flex items-center"
                    active-class="text-blue-700"
                    inactive-class="text-black"
                    :to="link._path"
                >
                    <span class="truncate relative text-inherit">{{ link.title }}</span>
                </ULink>
            </li>
        </ul>
    </Transition>
</template>

<script setup>
const props = defineProps(['link', 'ui']);
const isOpen = ref(false);
</script>

<style>
.v-enter-active,
.v-leave-active {
    transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
}
</style>
