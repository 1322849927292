<template>
    <PageHeader/>
    <main>
        <slot />
    </main>
    <PageFooter/>
</template>

<script setup>

</script>

<style>
.nuxt-icon.nuxt-icon--fill svg {
    width: initial;
    height: initial;
    margin-bottom: 0;
}
</style>
