<template>
    <header class="sticky top-0 z-10 bg-white shadow-md">
        <UContainer :ui="{constrained: 'px-2'}">
            <div class="grid grid-cols-[minmax(88px,auto)_1fr_auto_min-content] xl:grid-cols-[minmax(120px,auto)_1fr_300px_max-content] xl:flex-nowrap items-center gap-y-3 gap-x-2 xl:gap-x-12 py-3">
                <div class="col-start-1 col-end-2 max-w-20 lg:max-w-32">
                    <ULink to="/">
                        <img src="/images/logo.svg" loading="lazy" alt="OCAutoAid" class="w-full" />
                    </ULink>
                </div>

                <PageSiteNavigation/>

                <UIAddress/>

                <div class="col-start-3 col-end-4 row-start-1 lg:col-start-4">
                    <UButton variant="solid" size="lg" :to="quoteLink">Get a Quote</UButton>
                </div>
            </div>
        </UContainer>
    </header>
</template>

<script setup>
const route = useRoute();

const quoteLink = computed(() => {
    return route.path === '/' ? '#contact-form' : '/contact-us'
})
</script>
