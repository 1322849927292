<template>
    <nav class="hidden md:flex flex-auto col-start-2 col-end-3" :class="ui.wrapper">
        <ul :class="ui.container" class="gap-x-0 xl:gap-x-2.5">
            <li v-for="(link, index) of navLinks" :key="`link-${index}`" :class="ui.inner">
                <ULink
                    :to="link.to"
                    :class="[ui.base, ui.before, ui.after]"
                    :active-class="ui.active"
                    :inactive-class="ui.inactive"
                    :target="link.target"
                    v-if="!link.children"
                >
                    <span class="truncate relative">{{link.title}}</span>
                </ULink>

                <UDropdown
                    :items="[link.children]"
                    mode="hover"
                    :ui="{
                        padding: 'p-5',
                        width: 'w-auto',
                        rounded: 'rounded-0.5xl',
                        ring: '',
                        shadow: ['shadow-dropdown'],
                        item: {
                            base: 'text-black font-medium',
                            active: 'text-blue-700',
                            inactive: 'text-black'
                        }}"
                    :popper="{ offsetDistance: -10, placement: 'bottom-start' }" v-else
                >
                    <ULink
                        :to="link.to"
                        :class="[ui.base, ui.before, ui.after]"
                    >
                        <span class="truncate relative">{{link.title}}</span>

                        <UIcon name="heroicons:chevron-down-16-solid" class="w-6 h-6 -mr-2.5"></UIcon>
                    </ULink>

                    <template #item="{ item }">
                        <span class="truncate">{{ item.title }}</span>
                    </template>
                </UDropdown>
            </li>
        </ul>
    </nav>
</template>

<script setup>
import {horizontalNavigation} from '#ui/ui.config';

const props = defineProps(['ui', 'class']);

const {navigation: navLinks} = await useSiteNavigation();

const {ui} = useUI('horizontalNavigation', toRef(props, 'ui'), horizontalNavigation, toRef(props, 'class'))
</script>
