<template>
    <nav :class="ui.wrapper">
        <ul :class="ui.container">
            <li v-for="(link, index) of links" :key="`link-${index}`" :class="ui.items">
                <UINavItem :link="link" :ui="ui"/>
            </li>
        </ul>
    </nav>
</template>

<script setup>
import {verticalNavigation} from '#ui/ui.config';

const props = defineProps(['ui', 'class']);

const {navigation: links} = await useSiteNavigation();

const {ui} = useUI('verticalNavigation', toRef(props, 'ui'), verticalNavigation, toRef(props, 'class'));
</script>
